import React from 'react'
import "./loader.css"

export default function Loader() {
  return (
    <>
    
    <span className="loader"></span>
    
    </>
  )
}
